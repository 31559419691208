import React, { useState } from "react";
import {  Redirect } from "react-router-dom";
import {
  FaArrowRight,
} from "react-icons/fa";
import spinner from "../../assets/images/spinner.svg";
import Button from "../../components/Button";
import Divisor from "../../components/Divisor";
import ToastMessage from "../../components/ToastMessage";
import Header from "../../components/Header";
import { useAuth } from "../../context/contextAuth";
import { LoginPage, Options, InputGroup } from "./styles";
import api from "../../services/api";
import { Link } from "react-router-dom";
import ForgetPassword from "../../modals/ForgetPassword";
import SubModal from "../../components/SubModal";
import { useSubModal } from "../../context/contextSubModal";

export const AuthPage = (props) => {
  const { signIn, hasError, setHasError, signed, user } = useAuth();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loader, setLoader] = useState();
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const {setIsSubModalVisible, isSubModalVisible, setSubModalContent} = useSubModal();

  async function handleLogin(e){
    e.preventDefault();
    
    return await api.post("oauth", {
        login,
        password,
      })
      .then(setLoader(true))
      .then((response) => {
        setLoader(false);
        signIn(response.data);
      })
      .catch((err) => {
        setLoader(false);
          if (login === ''){
            setErrorLogin(true);
            setErrorLogin(false);
            return;
          } else if(password === ''){
            setErrorPassword(true);
            setErrorPassword(false);
            return;
          }
        setHasError(true);
        setHasError(false);
        setPassword('');
      });
  };

  if (signed && user.is_admin === false) {
    return <Redirect from="/auth" to="/dashboard" />;
  } else if (signed && user.is_admin === true) {
    return <Redirect from="/auth" to="/admin" />;
  } else {
    return (
      <>
        {isSubModalVisible && <SubModal onClose={() => setIsSubModalVisible(false)} />}
        {hasError && <ToastMessage variant="error" message="Nome de usuário ou senha incorretos!"/>}
        {errorLogin && <ToastMessage variant="warning" message="Preencha um nome de usuário antes de continuar!"/>}
        {errorPassword && <ToastMessage variant="warning" message="Preencha uma senha antes de continuar!"/>}
        <LoginPage className="container">
          <Header size={150} location="https://atlantico.digital"/>
          <main>
            <form onSubmit={handleLogin}>
              <InputGroup>
                <input name="login" type="text" placeholder="Usuário" value={login} onChange={(e) => setLogin(e.target.value)} autoComplete="on"/>
              </InputGroup>
              <InputGroup>
                <input name="password" type="password" placeholder="Senha" value={password} onChange={(e) => setPassword(e.target.value)}  autoComplete="on"/>
              </InputGroup>
                <Button type="button" bgcolor="var(--orange)" color="#fff" border="var(--orange)" hover="var(--orange-hover)" size="medium" hollow={false} expansive={true}>
                  {loader ? <img src={spinner} alt="Logar"/> : <FaArrowRight size={20} />}
                </Button>
                <Divisor />
            </form>
            <div className="forget-password">
              <h5>
                <span onClick={() => {
                  setIsSubModalVisible(true);
                  setSubModalContent(<ForgetPassword />)
                }}>
                  Esqueceu sua senha?
                </span>
              </h5>
            </div>
            <Options>
              <Link to={{ pathname: "/auth/politica-de-privacidade", state: {modal: true} }}>
                <small>Política de Privacidade</small>
              </Link>
            </Options>
          </main>
        </LoginPage>
      </>
    );
  }
};
