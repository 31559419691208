import {createGlobalStyle} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root {
    --atlantico: #1180c3;
    --atlantico-hover: #156b9f;
    --blue: #2699fb;
    --blue-hover: #2473b8;
    --green: #3cd56b;
    --green-hover: #32b059;
    --orange: #e58800;
    --orange-hover: #c37400;
    --purple: #5655dd;
    --purple-hover: #4444b6;
    --red: #ff2222;
    --red-hover: #c41d1d;
    --pink: #fb56ac;
    --gray: #aab7c3;

    --background: ${props => props.darkMode ? '#121212' : '#f1f7fa'};
    --modal: ${props => props.darkMode ? '#1f1f1f' : "#fff"};

    --text: ${props => props.darkMode ? '#fff' : '#000'};
    --file: ${props => props.darkMode ? '#fff' : '#000'};
    --folder: #2699fb;
    --divisor: ${props => props.darkMode ? '#3d3d3d' : '#e8e8e8'};
    --input: ${props => props.darkMode ? '#fff' : '#000'};
    --item-list: ${props => props.darkMode ? '#121212' : '#f4f4f4'};
    --tutorialbox: ${props => props.darkMode ? '#000' : '#fff'};
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body, html {
    background: var(--background);
  }

  #root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  body,
  input,
  button,
  textarea,
  select {
    font: 300 15px Roboto;
    outline: 0;
  }

  button {
    cursor: pointer;
  }

/*   input,
  button,
  textarea,
  select,
  ul,
  li,
  a, 
  svg {
    pointer-events: ${props => props.tutorial && 'none'};
  } */

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 300;
  }

  .container {
    width: 100vw;
    max-width: 100%;
    height: 100%;
  }

  @media (min-width: 700px) {
    :root {
      // font-size: 62.5%;
    }
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #b6bfcb;
    -webkit-box-shadow: inset 0 0 6px #C1C1C1;
  }

  .snackbar {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  
  #client-snackbar {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 700px) {
    #client-snackbar {
      padding: 3px 0;
      font-size: 0.7rem;
    }
  }

  .jss1, .makeStyles-root-1{
    z-index: 99999999999999999999999 !important;
  }




  #client-snackbar svg {
    margin-bottom: 0;
  }

  #mui-30825 {
    font: 3rem;
  }

`

