import styled from "styled-components";

export const RecoverPage = styled.div`
  width: 100vw;
  height: 100vh;
  main {
    padding: 2rem;
    .text {
      text-align: center;
      h2,
      h3 {
        margin-bottom: 1rem;
      }
    }
    .loginInput {
      width: 100%;
      position: relative;
      .submit-icon {
        position: absolute;
        top: 15px;
        right: 0;
        z-index: 10;
        margin-right: 1rem;
        margin-top: 1rem;
      }
      input {
        width: 100%;
        height: var(--small);
        margin-top: 0.8rem;
        border-radius: 1rem;
        outline: 0;
        padding: 1.1rem 2rem;
        color: #aaa69e;
        border: 1px solid black;
      }
    }
  }

  @media (min-width: 700px) {
    main {
      width: 40%;
      max-width: 740px;
      margin: 0 auto;
      .loginInput {
        input {
          height: var(--medium);
          padding: 1.5rem 2rem;
        }
      }
    }
  }

  @media (min-width: 1025px) {
    main {
      width: 20%;
      max-width: 740px;
      margin: 0 auto;
    }
  }
`;

export const PageHeader = styled.header`
  display: flex;
  flex-direction: column;
  background-color: var(--atlantico);
  #top-bar-container {
    width: 100%;
    padding: 1.3rem;
    padding-bottom: 0;
    align-items: center;
    a {
      height: 3.2rem;
      text-decoration: none;
      color: white;
    }
  }
  #header-content {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 3rem;
    padding-top: 0;
    div {
      position: absolute;
      top: calc(234px - 62.5px);
    }
  }

  @media (min-width: 700px) {
    #top-bar-container {
      padding: 4rem 10rem;
    }
  }

  @media (min-width: 1025px) {
    #top-bar-container {
      padding: 2rem;
      padding-bottom: 0;
    }
  }
`;