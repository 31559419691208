import React, { createContext, useState, useContext, useEffect } from "react";
import api from "../services/api";
import {rr} from "../services/runrunit";
const RunrunitContext = createContext();

export default function RunrunitProvider({ children }) {
    const [users, setUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [att, setAtt] = useState(false);

    useEffect(() => {
        rr.get('users', {
            headers: {
                "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
                "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
            }
        })
        .then(response => setUsers(response.data))
        .catch(err => console.log(err))

        rr.get('teams', {
            headers: {
                "App-Key": process.env.REACT_APP_RUNRUNIT_TOKEN,
                "User-Token": process.env.REACT_APP_RUNRUNIT_USER_TOKEN
            }
        })
        .then(response => setTeams(response.data))
        .catch(err => console.log(err))
    }, []);
    
    const headers = {
        Authorization: localStorage.getItem('@Atlantico:token'),
    }

    async function createTask(data){
        setLoading(true);
        await api.post('/v1/tasks', data, {headers})
        .then(response => {
            setLoading(false);
            setSuccess(true);
            setSuccess(false);
        })
        .catch(err => {
            setLoading(false)
            setError(true);
            setError(false);
        })
    }

    async function updateTask(data, id){
        setLoading(true);
        await api.put(`/v1/tasks/${id}`, data, {headers})
        .then(response => {
            setLoading(false);
            setSuccess(true);
            setSuccess(false);
        })
        .catch(err => {
            setLoading(false)
            setError(true);
            setError(false);
        })
    }

    return (
        <RunrunitContext.Provider value={{ users, teams, createTask, updateTask, loading, success, error, att, setAtt }}>
            {children}
        </RunrunitContext.Provider>
    );
}

export function useRunrunit() {
    const context = useContext(RunrunitContext);
    const { users, teams, createTask, updateTask, loading, success, error, att, setAtt } = context;
    return { users, teams, createTask, updateTask, loading, success, error, att, setAtt };
}
