import React from "react";

import { Content } from "./styles";

const ModalContent = ({ children, hasFooter, displayFlex }) => {
  return (
    <Content hasFooter={hasFooter} displayFlex={displayFlex}>
      {children}
    </Content>
  );
};

export default ModalContent;
