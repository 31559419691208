import styled from "styled-components";

export const Content = styled.div`
  height: 100%;
  display: ${(props) => (props.displayFlex ? "flex" : null)};
  justify-content: ${(props) => (props.displayFlex ? "center" : null)};
  flex-direction: ${(props) => (props.displayFlex ? "column" : null)};
  h4 {
    line-height: 1rem;
  }
  ul {
    height: 100%;
  }
  @media (min-width: 700px) {
    h4 {
      line-height: 2rem;
    }
  }
  .link {
        width: 10%;
        padding-top: 1.5rem;
            a {
                display: flex;
                align-content: left;
                align-items: center;
                text-decoration: none;
                text-align: left;
                svg {
                    margin-right: 1rem;
                    margin-bottom: 0;
                    color: var(--blue);
                    transition: color 0.25s;
                }
                p {
                    color: black;
                }
                &:hover {
                    svg {
                        color: var(--blue-hover);
                    }
                }
            }
    }
`;
