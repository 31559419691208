import React from "react";

import { StyledDivisor } from "./styles";

const Divisor = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <StyledDivisor />
    </div>
  );
};

export default Divisor;
