import React, { createContext, useState, useContext } from "react";

const UploadModalContext = createContext();

export default function UploadProvider({ children }) {
  const [isUploadVisible, setIsUploadVisible] = useState(false);
  const [uploadContent, setUploadContent] = useState();
  const [uploadTitle, setUploadTitle] = useState();
  const [att, setAtt] = useState(false);

  return (
    <UploadModalContext.Provider
      value={{
        isUploadVisible,
        setIsUploadVisible,
        uploadContent,
        setUploadContent,
        setUploadTitle,
        uploadTitle,
        att,
        setAtt,
      }}
    >
      {children}
    </UploadModalContext.Provider>
  );
}

export function useUploadModal() {
  const uploadModal = useContext(UploadModalContext);
  const {
    isUploadVisible,
    setIsUploadVisible,
    uploadContent,
    setUploadContent,
    setUploadTitle,
    uploadTitle,
    att,
    setAtt,
  } = uploadModal;
  return {
    isUploadVisible,
    setIsUploadVisible,
    uploadContent,
    setUploadContent,
    setUploadTitle,
    uploadTitle,
    att,
    setAtt,
  };
}
