import React, { createContext, useState, useContext } from "react";

const SurveyContext = createContext();

export default function SurveyProvider({ children }) {
  const [loader, setLoader] = useState(false);

  const [day, setDay] = useState(false);
  const [week, setWeek] = useState(true);
  const [month, setMonth] = useState(false);
  const [custom, setCustom] = useState(false);
  const [dayInit, setDayInit] = useState('');
  const [dayEnd, setDayEnd] = useState('');

  const [filter, setFilter] = useState('week');

  const [type, setType] = useState('task');

  const [qtdVeryDissatisfied, setQtdVeryDissatisfied] = useState(0);
  const [qtdDissatisfied, setQtdDissatisfied] = useState(0);
  const [qtdNeutral, setQtdNeutral] = useState(0);
  const [qtdSatisfied, setQtdSatisfied] = useState(0);
  const [qtdVerySatisfied, setQtdVerySatisfied] = useState(0);

  const totalVotes = qtdVeryDissatisfied + qtdDissatisfied + qtdNeutral + qtdSatisfied + qtdVerySatisfied;

  const [comments, setComments] = useState([]);

  const [customDates, setCustomDates] = useState(false);
  const [isGraphActive, setIsGraphActive] = useState(true);


  const data = [
    {
        "name": "Muito Insatisfeito",
        "quantidade": qtdVeryDissatisfied,
        "color": "#FD6643",
    },
    {
        "name": "Insatisfeito",
        "quantidade": qtdDissatisfied,
        "color": "#FDA043",
    },
    {
        "name": "Neutro",
        "quantidade": qtdNeutral,
        "color": "#FED940",
    },
    {
        "name": "Satisfeito",
        "quantidade": qtdSatisfied,
        "color": "#C5FE40",
    },
    {
        "name": "Muito Satisfeito",
        "quantidade": qtdVerySatisfied,
        "color": "#42FE40",
    },
  ]

  const handleButtons = (type) => {
    type === 'day' ? setDay(true)  : setDay(false);
    type === 'week' ? setWeek(true) : setWeek(false);
    type === 'month' ? setMonth(true) : setMonth(false);
    type === 'custom' ? setCustom(true) : setCustom(false);
    setDayInit('');
    setDayEnd('');
    setFilter(type);
    setIsGraphActive(false);
    setIsGraphActive(true);
  }
  

  return (
    <SurveyContext.Provider
      value={{
        day,  setDay, week, setWeek, month, setMonth, custom, setCustom, dayInit, setDayInit, dayEnd, setDayEnd, filter, setFilter,
        qtdVeryDissatisfied, setQtdVeryDissatisfied, qtdDissatisfied, setQtdDissatisfied, qtdNeutral, setQtdNeutral, customDates, setCustomDates,
        qtdSatisfied, setQtdSatisfied, qtdVerySatisfied, setQtdVerySatisfied, comments, setComments, handleButtons, data,
        isGraphActive, setIsGraphActive, totalVotes, loader, setLoader, type, setType
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
}

export function useSurvey() {
  const survey = useContext(SurveyContext);
  const { day, setDay, week, setWeek, month, setMonth, custom, setCustom, dayInit, setDayInit, dayEnd, setDayEnd, filter, setFilter,
    qtdVeryDissatisfied, setQtdVeryDissatisfied, qtdDissatisfied, setQtdDissatisfied, qtdNeutral, setQtdNeutral, customDates, setCustomDates,
    qtdSatisfied, setQtdSatisfied, qtdVerySatisfied, setQtdVerySatisfied, comments, setComments, handleButtons, data,
    isGraphActive, setIsGraphActive, totalVotes, loader, setLoader, type, setType } = survey;
  return {
    day, setDay, week, setWeek, month, setMonth, custom, setCustom, dayInit, setDayInit, dayEnd, setDayEnd, filter, setFilter,
        qtdVeryDissatisfied, setQtdVeryDissatisfied, qtdDissatisfied, setQtdDissatisfied, qtdNeutral, setQtdNeutral, customDates, setCustomDates,
        qtdSatisfied, setQtdSatisfied, qtdVerySatisfied, setQtdVerySatisfied, comments, setComments, handleButtons, data,
        isGraphActive, setIsGraphActive, totalVotes, loader, setLoader, type, setType
  };
}
