import React, { useState } from "react";
import spinner from "../../assets/images/spinner.svg";
import { FaLock } from "react-icons/fa";
import Button from "../../components/Button";
import Message from "../../components/Message";
import ModalButtons from "../../components/ModalButtons";

import { DivNewFolder } from "./styles";

import api from "../../services/api";
import { useSubModal } from "../../context/contextSubModal";
import { RecoverPasswordRequestSuccess } from "../RecoverPasswordRequestSuccess";

export const ForgetPassword = () => {
  const [formData, setFormData] = useState({login: ""});
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState();
  const {setIsSubModalVisible, setSubModalContent} = useSubModal();

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  async function handleCreateRequestForNewPassword(e) {
    e.preventDefault();
    const { login } = formData;

    return await api.post("oauth/forgot", {
        login,
      })
      .then(setLoader(true))
      .then((response) => {
        setSubModalContent(<RecoverPasswordRequestSuccess emails={response.data.sendedTo}/>)
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setError(err);
        console.log(err)
      });
  }

  return (
    <DivNewFolder>
      <FaLock size={32} />
      <h3>
        <strong>Recuperação de senha</strong>
      </h3>
      <h4>Informe seu nome de usuário para redefinir sua senha.</h4>
      <form onSubmit={handleCreateRequestForNewPassword}>
        <input
          placeholder="Digite seu login aqui"
          name="login"
          onChange={handleInputChange}
        />
        {error ? <Message severity="warning" title="Usuário não encontrado!" /> : null}
        <ModalButtons>
        <hr />
          <Button
            type="submit"
            bgcolor="var(--blue)"
            color="#fff"
            border="var(--blue)"
            hover="var(--blue-hover)"
            expansive={true}
            hollow={false}
            size="small"
          >
            {loader ? <img src={spinner} alt="loading"/> : "SOLICITAR"}
          </Button>
          
          <Button
            expansive={true}
            color="var(--gray)"
            border="var(--gray)"
            hollow={true}
            size="small"
            onClick={e => {
              setIsSubModalVisible(false);
              setSubModalContent(null)
            }}
          >
            CANCELAR
          </Button>
        </ModalButtons>
      </form>
    </DivNewFolder>
  );
};

export default ForgetPassword;
