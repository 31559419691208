import React from "react";

import logo from "../../assets/images/logo.svg";
import spinner from "../../assets/images/spinner.svg";

import { PageLoadingDiv } from "./styles";

const PageLoading = () => {
  return (
    <PageLoadingDiv>
      <img src={logo} alt="" />
      <img src={spinner} alt="" />
    </PageLoadingDiv>
  );
};

export default PageLoading;
