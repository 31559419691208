import React, { createContext, useState, useContext } from "react";
import { GlobalStyles } from "../assets/styles/global";
const ColorModeContext = createContext();

export default function ColorModeProvider({ children }) {
  const [isEnabled, setIsEnabled] = useState(false);

  return (
    <ColorModeContext.Provider
      value={{
        isEnabled, setIsEnabled
      }}
    >
      <GlobalStyles darkMode={isEnabled}/>
      {children}
    </ColorModeContext.Provider>
  );
}

export function useColorMode() {
  const color = useContext(ColorModeContext);
  const { isEnabled, setIsEnabled } = color;
  return {
    isEnabled, setIsEnabled
  };
}
