import React from "react";

import { StyledButton } from "./styles";

const Button = (props) => {
  return (
    <StyledButton
      hollow={props.hollow}
      bgcolor={props.bgcolor}
      color={props.color}
      border={props.border}
      hover={props.hover}
      expansive={props.expansive}
      size={props.size}
      onClick={props.onClick}
      noMargin={props.noMargin}
      style={props.style}
    >
      <h4 style={{ textTransform: "uppercase" }}>{props.children}</h4>
    </StyledButton>
  );
};

export default Button;
