import React from "react";
import { useColorMode } from "../../context/contextColorMode";

import { Btns } from "./styles";

const ModalButtons = ({ children }) => {
  const {isEnabled} = useColorMode();
  return <Btns darkMode={isEnabled}>{children}</Btns>;
};

export default ModalButtons;
