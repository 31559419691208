import styled from "styled-components";

export const DivModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1202 !important;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .container {
    background-color: ${props => props.noBackground ? '#fff' : 'var(--modal)'};
    width: 95%;
    height: auto !important;
    color: #000;
    border-radius: 0.5rem;
    padding: 1rem;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      .title {
        text-transform: uppercase;
        color: var(--atlantico);
      }
      .close {
        background: none;
        border: 0;
        cursor: pointer;
      }
    }
    .content {
      height: 81%;
      ul {
        height: 100%;
      }
    }
  }

  @media (max-height: 400px) {
    .container {
      height: auto;
      .content {
        height: 70%;
      }
    }
  }

  @media (min-width: 700px) {
    .container {
      width: 70% !important;
      height: auto !important;
      padding: 1rem;
    }
  }

  @media (min-width: 1025px) {
    .container {
      width: 50% !important;
      height: auto !important;
      padding: 2rem;
    }
  }
`;
