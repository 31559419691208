import React, { createContext, useState, useContext } from "react";
const TutorialContext = createContext();

export default function TutorialProvider({ children }) {
  const [isTutorialActive, setIsTutorialActive] = useState();
  const [steps, setSteps] = useState([]);

  return (
    <TutorialContext.Provider value={{ steps, isTutorialActive, setIsTutorialActive, setSteps }}>
      {children}
    </TutorialContext.Provider>
  );
}

export function useTutorial() {
  const tutorial = useContext(TutorialContext);
  const { steps, isTutorialActive, setIsTutorialActive, setSteps } = tutorial;
  return {
    steps, isTutorialActive, setIsTutorialActive, setSteps
  };
}
