import styled from "styled-components";

export const PageHeader = styled.header`
  display: flex;
  background-color: var(--atlantico);
  justify-content: space-between;
  padding: 3rem 1rem;

  a{
      width: 30px;
      height: 30px;
  }
`;
