import styled from "styled-components";

export const DivNewFolder = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  h3 {
    margin: 1rem 0;
  }
  svg {
    color: var(--blue);
  }
  form {
    width: 100%;
    input {
      text-align: center;
      width: 100%;
      height: var(--small);
      margin-top: 1rem;
      border-radius: 0.5rem;
      outline: 0;
      padding: 1rem;
      color: var(--gray);
      border: 1px solid black;
    }
  }
`;
