import React, { createContext, useState, useContext } from "react";

const SubModalContext = createContext();

export default function ModalProvider({ children }) {
  const [isSubModalVisible, setIsSubModalVisible] = useState(false);
  const [subModalContent, setSubModalContent] = useState();

  return (
    <SubModalContext.Provider
      value={{
        isSubModalVisible,
        setIsSubModalVisible,
        subModalContent,
        setSubModalContent,
      }}
    >
      {children}
    </SubModalContext.Provider>
  );
}

export function useSubModal() {
  const subModal = useContext(SubModalContext);
  const {
    isSubModalVisible,
    setIsSubModalVisible,
    subModalContent,
    setSubModalContent,
  } = subModal;
  return {
    isSubModalVisible,
    setIsSubModalVisible,
    subModalContent,
    setSubModalContent,
  };
}
