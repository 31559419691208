
import React from 'react';
import { useColorMode } from '../../context/contextColorMode';
import {AlertUI} from './styles';

const Message = ({message, style, severity}) => {
    const {isEnabled} = useColorMode();
    return (
        <AlertUI severity={severity} icon={false} darkMode={isEnabled} style={{...style, width: '100%', backgroundColor: isEnabled ? '#000' : '', color: isEnabled ? '#fff' : '#000'}}>
            <h4>{message}</h4>
        </AlertUI>
    )
}

export default Message;