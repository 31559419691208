import React from "react";
import Button from "../../components/Button";
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { useSubModal } from "../../context/contextSubModal";

import grupoImg from "../../assets/images/grupo.svg";

import { RecoverPasswordRequestSuccessDiv } from "./styles";

export const RecoverPasswordRequestSuccess = ({ emails }) => {
  const { setIsSubModalVisible } = useSubModal();
  return (
    <>
      <RecoverPasswordRequestSuccessDiv>
        <ModalContent>
          <img src={grupoImg} alt="" />
          <h3>
            <strong>E-mail de recuperação enviado com sucesso para:</strong>
          </h3>
          {emails.map((email) => {
            return <h3 key={email}>{email}</h3>;
          })}
        </ModalContent>
        <ModalButtons>
          <hr />
          <Button
            color="var(--gray)"
            border="var(--gray)"
            expansive={true}
            hollow={true}
            size="small"
            onClick={() => setIsSubModalVisible(false)}
          >
            FECHAR
          </Button>
        </ModalButtons>
      </RecoverPasswordRequestSuccessDiv>
    </>
  );
};