import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import spinner from "../../assets/images/spinner.svg";
import Button from "../../components/Button";
import Divisor from "../../components/Divisor";
import PageLoading from "../../components/PageLoading";
import SubModal from "../../components/SubModal";
import { useSubModal } from "../../context/contextSubModal";
import RecoverPasswordSuccess from "../../modals/RecoverPasswordSuccess";
import PasswordChecklist from "react-password-checklist"

import { RecoverPage, PageHeader } from "./styles";

import api from "../../services/api";
import ToastMessage from "../../components/ToastMessage";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const RecoverPassword = (props) => {
  const {
    isSubModalVisible,
    setIsSubModalVisible,
    setSubModalContent,
  } = useSubModal();

  const token = useState(props.match.params.token);

  const [formData, setFormData] = useState({
    token: props.match.params.token,
    newPassword: "",
    verifyPassword: "",
  });

  const [hasError, setHasError] = useState(false);
  const [loader, setLoader] = useState();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('sdasd');
  const history = useHistory();
  const [newPasswordVisible, setNewPasswordVisible] = useState(true);
  const [verifyPasswordVisible, setVerifyPasswordVisible] = useState(true);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  useEffect(() => {
    async function recoverPassword(){
      api.post("oauth/recover", {
          token,
          newPassword: "",
          verifyPassword: "",
        })
        .then((response) => {
          setLoading(false);
          return;
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            history.push("/invalidtoken");
          }
        });
    }

    recoverPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggleVisibleNewPassword() {
    setNewPasswordVisible(!newPasswordVisible);
  }

  function toggleVisibleVerifyPassword() {
      setVerifyPasswordVisible(!verifyPasswordVisible);
  }

  async function handleResetPassword(e) {
    e.preventDefault();
    const { token, newPassword, verifyPassword } = formData;
    return await api.post("oauth/recover", {
        token,
        newPassword,
        verifyPassword,
      })
      .then(setLoader(true))
      .then((response) => {
        setLoader(false);
        setIsSubModalVisible(true);
        setSubModalContent(<RecoverPasswordSuccess />);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.status === 400) {
          setMessage('Verifique se sua senha possui todos os requisitos mínimos!');
          setHasError(true);
          setHasError(false);
        } else if (err.response.status === 422) {
          setMessage("As senhas não coincidem!");
          setHasError(true);
          setHasError(false);
        }
      });
  }

  return (
    <RecoverPage className="container">
      {loading ? <PageLoading /> : null}
      {isSubModalVisible ? <SubModal /> : null}
      <PageHeader>
        <div id="top-bar-container"></div>

        <div id="header-content">
          <img src={logo} width="200" alt="Atlântico Digital" />
        </div>
      </PageHeader>

      <main>
        <div className="text">
          <h2>
            <strong>Recuperação de senha</strong>
          </h2>
          <h3>
            Muito bem! Falta pouco para recuperar seu acesso. Digite abaixo a
            nova senha que deseja.
          </h3>
        </div>

        <form onSubmit={handleResetPassword}>
          {newPasswordVisible ? (
            <div className="loginInput">
                <input name="newPassword" type="password" placeholder="Nova senha" onChange={handleInputChange} maxLength={20}/>
                <FaEyeSlash className="submit-icon" size={16} style={{ color: "#A0A0A0", cursor: "pointer" }} onClick={toggleVisibleNewPassword}/>
            </div>
            ) : (
            <div className="loginInput">
                <input name="newPassword" type="text" placeholder="Nova senha"  maxLength={20} onChange={handleInputChange}/>
                <FaEye className="submit-icon" size={16} style={{ color: "#A0A0A0", cursor: "pointer" }} onClick={toggleVisibleNewPassword}/>
            </div>
          )}

          {verifyPasswordVisible ? (
            <div className="loginInput">
              <input name="verifyPassword" type="password" maxLength={20} placeholder="Confirme a nova senha" onChange={handleInputChange}/>
              <FaEyeSlash className="submit-icon" size={16} style={{ color: "#A0A0A0", cursor: "pointer" }} onClick={toggleVisibleVerifyPassword}/>
            </div>
          ) : (
            <div className="loginInput">
              <input name="verifyPassword" type="text" maxLength={20} placeholder="Confirme a nova senha" onChange={handleInputChange}/>
              <FaEye className="submit-icon" size={16} style={{ color: "#A0A0A0", cursor: "pointer" }} onClick={toggleVisibleVerifyPassword}/>
            </div>
          )}
          
          <PasswordChecklist
            rules={["minLength", "specialChar","number", "capital", "lowercase", "match"]}
            minLength={5}
            iconSize={12}
            value={formData.newPassword}
            valueAgain={formData.verifyPassword}
            style={{marginTop: '2rem', marginBottom: '2rem'}}
            onChange={(isValid) => {}}
            messages={{
              minLength: "A senha deve possuir de 6 a 20 caracteres.",
              specialChar: "A senha deve possuir ao menos um caractere especial (@#$%).",
              number: "A senha deve possuir ao menos um número (0..9).",
              lowercase: "A senha deve possuir ao menos uma letra minúscula (a..z).",
              capital: "A senha deve possuir ao menos uma letra maiúscula (A..Z).",
              match: "As senhas precisam coincidir.",
            }}
          />
          
          {hasError && <ToastMessage variant="error" message={message} />}

          <Button
            type="submit"
            bgcolor="var(--orange)"
            color="#fff"
            border="var(--orange)"
            hover="var(--orange-hover)"
            size="medium"
            hollow={false}
            expansive={true}
          >
            {loader ? <img src={spinner} alt="Alterar senha"/> : "ALTERAR SENHA"}
          </Button>
        </form>

        <Divisor />
      </main>
    </RecoverPage>
  );
};

export default RecoverPassword;
