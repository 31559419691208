import styled from "styled-components";

export const InputGroup = styled.div`
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: var(--small);
    margin-top: 0.5rem;
    border-radius: 1rem;
    outline: 0;
    padding: 1rem;
    color: var(--gray);
    border: 0;
  }

  & + .input-block {
    margin-top: 0.5rem;
  }

  @media (min-width: 700px) {
    input {
      height: var(--medium);
      padding: 1rem;
    }
  }
`;

export const LoginPage = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f1f7fa;
  main {
    padding: 1rem;
    .forget-password {
      display: flex;
      justify-content: center;
      h5 {
        margin-top: 1.3rem;
        span {
          cursor: pointer;
          color: var(--blue);
          transition: color 0.2s;
          &:hover {
            color: var(--orange);
          }
        }
      }
    }
  }

  &.container{
    height: 100vh;
  }
  
  @media (min-width: 700px) {
    main {
      width: 40%;
      max-width: 740px;
      margin: 0 auto;
    }
  }

  @media (min-width: 1025px) {
    main {
      width: 20%;
      max-width: 740px;
      margin: 0 auto;
    }
  }
`;

export const Options = styled.p`
  text-align: center;
  padding: 1rem;
  padding-bottom: 0;
  font-size: 0.8rem;

  a {
    text-decoration: none;
    color: #0a0a0a;
    cursor: pointer;
    &:hover {
      color: var(--blue);
    }
  }

  @media (min-width: 700px) {
    font-size: 1rem;
  }
`;
