import React, { createContext, useState, useContext } from "react";

const NewRequestContext = createContext();

export default function NewRequestProvider(props) {
  const [company, setCompany] = useState('');
  const [companies, setCompanies] = useState([]);
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [requestNumber, setRequestNumber] = useState();


  return (
    <NewRequestContext.Provider
      value={{
       date, setDate, company, setCompany, type, setType, title, setTitle, description, setDescription, requestNumber, setRequestNumber, companies, setCompanies
      }}
    >
      {props.children}
    </NewRequestContext.Provider>
  );
};

export function useNewRequest() {
  const newrequest = useContext(NewRequestContext);
  const {date, setDate, company, setCompany, type, setType, title, setTitle, description, setDescription, requestNumber, setRequestNumber, companies, setCompanies} = newrequest;
  return { date, setDate, company, setCompany, type, setType, title, setTitle, description, setDescription, requestNumber, setRequestNumber, companies, setCompanies};
}
