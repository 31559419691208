import styled from "styled-components";

export const Btns = styled.div`
  height: auto;
  width: 100%;
  hr {
    margin-top: 1rem;
    border: var(--divisor);
  }
`;
