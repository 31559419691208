import styled from "styled-components";

export const RecoverPasswordSuccessDiv = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    margin-top: 1rem;
  }
  img {
    width: 50%;
  }
`;
