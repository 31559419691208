import React from "react";
import { DivModal } from "./styles";
import { useColorMode } from "../../context/contextColorMode";
import { useSubModal } from "../../context/contextSubModal";

const SubModal = ({ id = "submodal", noBackground }) => {
  const {isEnabled} = useColorMode();
  const {subModalContent, setIsSubModalVisible, setSubModalContent} = useSubModal();

  const closeModal = e => {
      setIsSubModalVisible(false);
      setSubModalContent(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === id) closeModal();
  };

  return (
    <DivModal id={id} onClick={handleOutsideClick} darkMode={isEnabled} noBackground={noBackground}>
      <div className="container">
        <div className="content">{subModalContent}</div>
      </div>
    </DivModal>
  );
};

export default SubModal;
