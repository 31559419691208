import React, { createContext, useState, useContext } from "react";
const AdminCompanyContext = createContext();

export default function AdminCompanyProvider({ children }) {
  const [company, setCompany] = useState(null);

  return (
    <AdminCompanyContext.Provider value={{company, setCompany}}>
      {children}
    </AdminCompanyContext.Provider>
  );
}

export function useAdminCompany() {
  const context = useContext(AdminCompanyContext);
  const { company, setCompany } = context;
  return { company, setCompany };
}
