import React, { createContext, useState, useContext } from "react";
const NotificationContext = createContext();

export default function NotificationProvider({ children }) {
  const [hasNotification, setHasNotification] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  return (
    <NotificationContext.Provider
      value={{
        hasNotification,
        setHasNotification,
        isOpened,
        setIsOpened,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  const notification = useContext(NotificationContext);
  const {
    hasNotification,
    setHasNotification,
    isOpened,
    setIsOpened,
  } = notification;
  return {
    hasNotification,
    setHasNotification,
    isOpened,
    setIsOpened,
  };
}
