import styled from "styled-components";

export const StyledButton = styled.button`
  background: ${(props) => (props.hollow ? "none" : props.bgcolor)};
  width: ${(props) => (props.expansive ? "100%" : null)};

  height: ${(props) =>
    (props.size === "small" && "2rem") ||
    (props.size === "medium" && "3rem") ||
    (props.size === "large" && "4rem")};
  border: 2px solid;
  border-radius: .7rem;

  border-color: ${(props) => `${props.border}`};
  color: ${(props) => `${props.color}`};
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  margin-top: ${props => props.noMargin ? null : '.5rem'};
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  h4 {
    display: flex;
    -webkit-display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    font-weight: 600;
    svg {
      margin-right: 1rem;
    }
  }

  &:hover {
    background: ${(props) => (props.hollow ? "none" : props.hover)};
    border-color: ${(props) => `${props.hover}`};
  }

  @media (min-width: 700px) {
    height: ${(props) =>
      (props.size === "small" && "2.5rem") ||
      (props.size === "medium" && "3rem") ||
      (props.size === "large" && "4rem")};
  }
`;
