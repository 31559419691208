import React, { createContext, useState, useContext } from "react";
const FilesContext = createContext();

export default function FilesProvider({ children }) {
  const [successDeleted, setSuccessDeleted] = useState(false);
  const [downloadFolderLoader, setDownloadFolderLoader] = useState(false);

  return (
    <FilesContext.Provider
      value={{successDeleted, setSuccessDeleted, downloadFolderLoader, setDownloadFolderLoader}}
    >
      {children}
    </FilesContext.Provider>
  );
}

export function useFiles() {
  const context = useContext(FilesContext);
  const { successDeleted, setSuccessDeleted, downloadFolderLoader, setDownloadFolderLoader } = context;
  return { successDeleted, setSuccessDeleted, downloadFolderLoader, setDownloadFolderLoader };
}
