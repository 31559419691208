import React, { createContext, useState, useEffect, useContext } from "react";
import api from "../services/api";
import PageLoading from "../components/PageLoading";
import { useColorMode } from "./contextColorMode";
import { useTutorial } from "./contextTutorial";

const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [hasError, setHasError] = useState(false);
  const [user, setUser] = useState(null);
  const [primary, setPrimary] = useState(null);
  const [loading, setLoading] = useState(true);
  const {setIsEnabled} = useColorMode();
  const {setIsTutorialActive} = useTutorial();
  const [token, setToken] = useState();

  useEffect(() => {
    async function loadStoragedData() {
      const storagedUser = localStorage.getItem("@Atlantico:user");
      const storagedPrimary = localStorage.getItem("@Atlantico:primary");
      const storagedToken = localStorage.getItem("@Atlantico:token");
      const storagedColor = localStorage.getItem("@Atlantico:color");
      const storagedTutorial = localStorage.getItem("@Atlantico:tutorial");
      setToken(storagedToken);

      return await api.post("oauth/validateToken", {
          token: storagedToken,
        })
        .then((response) => {
          if (response.data.valid) {
            api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
            setUser(JSON.parse(storagedUser));
            setPrimary(JSON.parse(storagedPrimary));
            setIsEnabled(JSON.parse(storagedColor));
            setIsTutorialActive(JSON.parse(storagedTutorial));
            setLoading(false);
          } else {
            setUser(null);
            setPrimary(null);
            localStorage.removeItem("@Atlantico:primary");
            localStorage.removeItem("@Atlantico:user");
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });

    }

    loadStoragedData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  if (loading) {
    return <PageLoading />;
  }

  function signIn(response) {
    if(response.user.prospect === true){
      return;
    } else {
      setPrimary(response.primary);
      setUser(response.user);
      api.defaults.headers["Authorization"] = `Bearer ${response.token}`;
      localStorage.setItem("@Atlantico:user", JSON.stringify(response.user));
      localStorage.setItem("@Atlantico:primary", JSON.stringify(response.primary));
      localStorage.setItem("@Atlantico:token", response.token);
    }
    
  }

  function signOut() {
    setHasError(false);
    setPrimary(null);
    setUser(null);

    localStorage.removeItem("@Atlantico:token");
    localStorage.removeItem("@Atlantico:primary");
    localStorage.removeItem("@Atlantico:user");
    
  }

  

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        primary,
        signIn,
        signOut,
        loading,
        hasError,
        setHasError,
        setUser,
        setPrimary,
        token
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const auth = useContext(AuthContext);
  const {
    signed,
    user,
    primary,
    signIn,
    signOut,
    loading,
    hasError,
    setHasError,
    setUser,
    setPrimary,
  } = auth;
  return {
    signed,
    user,
    primary,
    signIn,
    signOut,
    loading,
    hasError,
    setHasError,
    setUser,
    setPrimary,
  };
}
