import styled from 'styled-components';
import Alert from '@material-ui/lab/Alert';

export const AlertUI = styled(Alert)`
    .MuiAlert-standardWarning{
        width: 100%;
        background-color: ${props => props.darkMode ? '#000' : ''};
        color: ${props => props.darkMode ? '#fff' : ''};
    }
    

    @media(min-width: 700px){
        .MuiAlert-message{
            font-size: 1rem;
        }
    }
`;
