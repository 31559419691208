import React, { createContext, useState, useContext } from "react";

const NewContactContext = createContext();

export default function NewContactProvider(props) {
  const [sector, setSector] = useState([]);
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cep, setCep] = useState('');
  const [number, setNumber] = useState('');
  const [companies, setCompanies] = useState([]);

  return (
    <NewContactContext.Provider
      value={{
        sector, setSector, cpf, setCpf, name, setName, date, setDate, email, setEmail, phone, setPhone, cep, setCep, number, setNumber, companies, setCompanies
      }}
    >
      {props.children}
    </NewContactContext.Provider>
  );
};

export function useNewContact() {
  const newcontact = useContext(NewContactContext);
  const {
    sector, setSector, cpf, setCpf, name, setName, date, setDate, email, setEmail, phone, setPhone, cep, setCep, number, setNumber, companies, setCompanies
  } = newcontact;
  return {
    sector, setSector, cpf, setCpf, name, setName, date, setDate, email, setEmail, phone, setPhone, cep, setCep, number, setNumber, companies, setCompanies
  };
}
