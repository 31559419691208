import React, { createContext, useState, useContext } from "react";
const SearchContext = createContext();

export default function SearchProvider({ children }) {
  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(true);
  const [result, setResult] = useState([]);

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        searchLoader,
        setSearchLoader,
        result, setResult
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  const busca = useContext(SearchContext);
  const { search, setSearch,searchLoader, setSearchLoader, result, setResult } = busca;
  return {
    search,
    setSearch,
    searchLoader,
    setSearchLoader,
    result, 
    setResult
  };
}
