import styled from "styled-components";

export const StyledDivisor = styled.hr`
  align-self: center;
  margin-top: 1rem;
  width: 12%;
  border: 0;
  background-color: #dce1e2;
  border-radius: 2rem;
  color: var(--divisor);
  height: 0.4rem;
`;
