import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import ModalContent from "../../components/ModalContent";
import ModalButtons from "../../components/ModalButtons";
import { useSubModal } from "../../context/contextSubModal";

import grupoImg from "../../assets/images/grupo.svg";

import { RecoverPasswordSuccessDiv } from "./styles";

const RecoverPasswordSuccess = () => {
  const { setIsSubModalVisible } = useSubModal();
  return (
    <>
      <RecoverPasswordSuccessDiv>
        <ModalContent>
          <img src={grupoImg} alt="" />
          <h3>
            <strong>Senha alterada com sucesso!</strong>
          </h3>
          <h3>Agora você poderá acessar sua conta segura.</h3>
        </ModalContent>
        <ModalButtons>
          <hr />
          <Link to="/auth" style={{ textDecoration: "none" }}>
            <Button
              color="var(--gray)"
              border="var(--gray)"
              expansive={true}
              hollow={true}
              size="small"
              onClick={() => {
                setIsSubModalVisible(false);
              }}
            >
              Voltar ao login
            </Button>
          </Link>
        </ModalButtons>
      </RecoverPasswordSuccessDiv>
    </>
  );
};

export default RecoverPasswordSuccess;
