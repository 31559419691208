import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { PageHeader } from './styles';
import logo from '../../assets/images/logo.svg';

const Header = ({size, location}) => {
    return (
        <PageHeader>

              <a href={location}>
                <FaArrowLeft size={30} color="white"/>
              </a>

              <img src={logo} width={size} alt="Atlântico Digital" />

            <FaArrowLeft size={30} color="var(--atlantico)"/>
        </PageHeader>
    )
}

export default Header;